.container{
  z-index: 1000;
}
.contact_form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: #090909;
  padding: 2rem;
  border-radius: 15px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  gap: 2rem;
  overflow: hidden;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }

  .contact_left_container {
    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      color: #fff;
      span{
        color: #A48348;
      }
    }

    .contact_text {
      font-size: 0.85rem;
      margin-bottom: 1rem;
      color: #fff;
    }
    .contact_left {
      display: flex;
      align-items: center;
      margin-top: 2rem;
      color: #fff;
      .icon {
        width: 60px;
        height: 60px;
        background: rgb(255, 87, 87);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 1rem;
      }
      p {
        font-size: 0.85rem;
      }
    }
    .social_icons {
      display: flex;
      margin-top: 2.6rem;
      a {
        margin-right: 1rem;
        cursor: pointer;
        color: #fff;
        &:hover {
          opacity: 0.8;
          transition:0.3s;
          color: #A48348;
        }

        svg {
          font-size: 1.3rem;
        }
      }
    }
  }
  .contact_right {
    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      color: #fff;
    }
    .row {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: 0.5rem;

      input {
        border: none;
        outline: none;
        margin-bottom: 0.6rem;
        padding: 1rem 1.5rem;
        background: #f3f3f3;
        border-radius: 5px;
        font-size: 0.85rem;
        font-family: "Poppins";
      }
      textarea {
        border: none;
        outline: none;
        margin-bottom: 0.5rem;
        padding: 1rem 1.5rem;
        background: #f3f3f3;
        border-radius: 5px;
        resize: none;
        min-height: 200px;
        font-size: 0.85rem;
        font-family: "Poppins";
      }
    }

    .btn {
      margin-top: 1rem;
      background: rgb(255, 87, 87);
      padding: 0.65rem 1rem;
      border-radius: 10px;
      font-size: 0.85rem;
      width: 6.2rem;
      text-transform: capitalize;
      color: #fff;
      cursor: pointer;
    }
    button {
    //     text-decoration: none;
    //     color: #fff;
        outline: none;
        border: none;
    //     background: #8c12f0;
    transition: 0.4s;
            &:hover{
                background-color: rgb(255, 87, 87);
                box-shadow: 0 0 10px red;
            }

    }
  }
}
