#home {
    display: flex;
    flex-direction: column;
    align-items: center;

    .profile {
        width: 110px;
        height: 110px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            pointer-events: none;
        }
    }
    .profile_text {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        h3 {
            color: #fff;
            font-size: 2rem;
            @media screen and (max-width: 500px) {
                font-size: 1.5rem;
            }
            span {
                color: rgb(255, 87, 87);
            }
        }
        .job {
            color: #fff;
            opacity: 0.5;
            font-size: 0.85rem;
        }
        .text {
            margin-top: 2rem;
            color: #fff;
            text-align: center;
            font-size: 5rem;
            line-height: 1.3;

            span {
                color: rgb(255, 87, 87);
            }

            @media screen and (max-width: 800px) {
                font-size: 3.5rem;
                 margin-top: 2rem;
            }
            @media screen and(max-width: 530px) {
                font-size: 2.5rem;
            }
        }
                a {
                    margin-top: 3rem;
                    text-decoration: none;
                    color: #000;
                    background: #fff;
                    padding: 0.65rem 1rem;
                    border-radius: 10px;
                    font-size: 0.85rem;
                    text-transform: capitalize;
                }
        div{
            position: absolute;
            background: rgba(255,255,255,0.15);
            color: #fff;
            padding: 0.35rem 1rem;
            border-radius: 25px;
            font-size: 0.85rem;
            cursor: pointer;
        }
        @media screen  and (max-width: 800px) {
            div {
                display: none;
            }
        }
        .web {
            right: -1rem;
            top: 3.5rem;
            transition: 0.4s;
            &:hover{
                background-color: rgb(255, 87, 87);
                box-shadow: 0 0 90px red;
            }
        }
        .ui {
            top: 2rem;
            left: 2rem;
            transition: 0.4s;
            &:hover{
                background-color: rgb(255, 87, 87);
                box-shadow: 0 0 90px red;
            }
        }
        .freelance {
            bottom: 2rem;
            left: 0;
            transition: 0.4s;
            &:hover{
                background-color: rgb(255, 87, 87);
                box-shadow: 0 0 90px red;
            }
        }
    }

}